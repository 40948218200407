import React, { useState } from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";

import { fetchApi } from "../utils/api"
import { PRODUCTS } from "../utils/products"
import { handlePhone } from "../js/validate-phone-number";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loan = () => {
  // const [whatsappMessage, setWhatsappMessage] = useState("");
  const formType = "PORTABILIDADE DE EMPRÉSTIMO";

  const [fieldValues, setFieldValues] = useState({})
  const [itemShow, setItemShow] = useState('visually-hidden')

  const handleChangeValues = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    if (
      fieldName === "income" &&
      fieldValue === "Empresa pública"
    ) {
      setItemShow("");
    } 
    else {
      setItemShow("visually-hidden");
    }

    setFieldValues((current) => {
      return {
        ...current,
        [fieldName]: fieldValue,
      };
    });
  }

  const handleFormSubmitLoanPortability = async (e) => {
    e.preventDefault()

    try {
      fetchApi({
        productId: PRODUCTS.PORTABILIDADE_EMPRESTIMO,
        formType,
        ...fieldValues
      })
      handleResetForm()
    } catch (err) {
      console.log(err)
    }
  }

  const handleResetForm = () => {
    setFieldValues({
      name: "",
      email: "",
      phone: "",
      debtBalance: "",
      installmentValue: "",
      remainingInstallments: "",
      income: "",
      governmentType: "",
      totalInstallments: ""
    })
  }

  console.log(fieldValues)

  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Transfira seus empréstimos com a Mais Valor e aproveite as melhores condições!"
        background_image="portabilidade.png"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-5 font_size_sm mb-5">
              Portabilidade de Empréstimo
            </h1>
            <h2 className="mb-4 font_size_sm_h3 mt-4">
              Faça aqui a portabilidade do seu empréstimo consignado
            </h2>
            <p className="mb-4">
              Transfira seus empréstimos para um único banco, possibilitando a
              diminuição das taxas de juros e melhores condições de pagamento
              das parcelas em aberto.
              <br />
              Com a portabilidade do Empréstimo Consignado você pode solicitar a
              transferência de seus créditos contratados em outros bancos e ter
              as parcelas debitadas em folha de pagamento. Consulte nossos
              especialistas hoje!
            </p>

            <button
              type="button"
              className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Contratar agora
            </button>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title mx-auto text-uppercase"
                      id="exampleModalLabel"
                    >
                      Portabilidade de empréstimo
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <h6
                    className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                    id="exampleModalLabel"
                  >
                    Dados Pessoais
                  </h6>
                  <div className="modal-body">
                    <form
                      id="form_portabilidade"
                      name="form_portabilidade"
                      onSubmit={handleFormSubmitLoanPortability}
                    >
                      <div className="input-group mb-3 ">
                        <label className="input-group-Text" htmlFor="nome_loan">
                          Nome
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Digite seu nome"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="nome_loan"
                          name="name"
                          value={fieldValues.name}
                          onChange={handleChangeValues}
                        />
                      </div>
                      <div className="d-lg-flex gap-lg-3">
                        <div className="input-group mb-3 ">
                          <label
                            className="input-group-Text"
                            htmlFor="email_loan"
                          >
                            E-mail
                          </label>
                          <input
                            type="email"
                            className="form-control "
                            placeholder="Digite seu e-mail"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            id="email_loan"
                            name="email"
                            value={fieldValues.email}
                            onChange={handleChangeValues}
                          />
                        </div>
                        <div className="input-group mb-3 ">
                          <label
                            className="input-group-Text"
                            htmlFor="phone_loan"
                          >
                            Celular
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Digite seu número"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            id="phone_loan"
                            name="phone"
                            maxLength="15"
                            onKeyUp={(e) => handlePhone(e)}
                            onChange={handleChangeValues}
                            // onChange={({ target }) =>
                            //   setPhone(unMask(target.value))
                            // }
                            value={fieldValues.phone}
                          />
                        </div>
                      </div>
                      <h6
                        className="modal-title text-uppercase  mt-lg-2"
                        id="exampleModalLabel"
                      >
                        Simulação
                      </h6>

                      <div className="d-lg-flex justify-content-between px-lg-3">
                        <div className="">
                          <p className=" mb-lg-0 mt-lg-1">
                            Seu empréstimo foi realizado em quantas parcelas?
                          </p>
                          <input
                            type="number"
                            step="1"
                            min="0"
                            name="totalInstallments"
                            onChange={handleChangeValues}
                            value={fieldValues.totalInstallments}
                          />
                        </div>
                        <div className="">
                          <p className=" mb-lg-0 mt-lg-1">
                            Qual é o valor do saldo devedor?
                          </p>
                          <input
                            className="float-lg-right"
                            type="number"
                            step="100.01"
                            min="0"
                            name="debtBalance"
                            value={fieldValues.debtBalance}
                            onChange={handleChangeValues}
                          />
                        </div>
                      </div>

                      <div className="d-lg-flex justify-content-between px-lg-3">
                        <div className="">
                          <p className="mb-lg-0 mt-lg-1">
                            Qual é o valor da parcela?
                          </p>
                          <input
                            type="number"
                            step="1"
                            min="0"
                            name="installmentValue"
                            value={fieldValues.installmentValue}
                            onChange={handleChangeValues}
                          />
                        </div>
                        <div className="">
                          <p className=" mb-lg-0 mt-lg-1">
                            Quantas parcelas ainda faltam?
                          </p>
                          <input
                            className="float-lg-right"
                            type="number"
                            step="1"
                            min="0"
                            name="remainingInstallments"
                            value={fieldValues.remainingInstallments}
                            onChange={handleChangeValues}
                          />
                        </div>
                      </div>

                      <div className="d-lg-flex justify-content-between px-lg-3">
                        <div className="mb-3">
                          <p className=" mb-lg-0 mt-lg-1">
                            Qual é a sua fonte de renda?
                          </p>
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            name="income"
                            value={fieldValues.income || ""}
                            onChange={handleChangeValues}
                          >
                            <option selected> [Selecione]</option>
                            <option value="Empresa privada">
                              Empresa privada
                            </option>
                            <option value="Empresa pública">
                              Empresa pública
                            </option>
                            <option value="INSS">INSS</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-lg-flex justify-content-between px-lg-3">
                        <div className={`mb-3 ${itemShow}`}>
                          <p className=" mb-lg-0 mt-lg-1">
                            Tipo de governo público?
                          </p>
                          <select
                            name="governmentType"
                            className="form-select form-select-sm"
                            value={fieldValues.governmentType || ""}
                            onChange={handleChangeValues}
                          >
                            <option selected> [Selecione]</option>
                            <option value="Governo federal">
                              Governo federal
                            </option>
                            <option value="Governo estadual">
                              Governo estadual
                            </option>
                            <option value="Governo municipal">
                              Governo municipal
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Enviar
                        </button>
                      </div>
                      {/* componente de mensagem toast */}
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

export default Loan;
