export const convenios = [
    
        {
            "id": 0,
            "name": "Governo da Bahia"
        },
        {
            "id": 1,
            "name": "Governo de Minas Gerais"
        },
        {
            "id": 2,
            "name": "Governo de Pernambuco"
        },
        {
            "id": 3,
            "name": "Governo de Santa Catarina"
        },
        {
            "id": 4,
            "name": "Governo de São Paulo"
        },
        {
            "id": 5,
            "name": "Governo do Amazonas"
        },
        {
            "id": 6,
            "name": "Governo do Maranhão"
        },
        {
            "id": 7,
            "name": "Governo do Paraná"
        },
        {
            "id": 8,
            "name": "Governo do Rio de Janeiro"
        },
        {
            "id": 9,
            "name": "INSS - Instituto Nacional de Seguro Social"
        },
        {
            "id": 10,
            "name": "Prefeitura de Alagoinhas"
        },
        {
            "id": 11,
            "name": "Prefeitura de Aliança"
        },
        {
            "id": 12,
            "name": "Prefeitura de Ananindeua"
        },
        {
            "id": 13,
            "name": "Prefeitura de Arapongas"
        },
        {
            "id": 14,
            "name": "Prefeitura de Aurelino Leal"
        },
        {
            "id": 15,
            "name": "Prefeitura de Barra do Choça"
        },
        {
            "id": 16,
            "name": "Prefeitura de Belém"
        },
        {
            "id": 17,
            "name": "Prefeitura Breves"
        },
        {
            "id": 18,
            "name": "Prefeitura de Cairu"
        },
        {
            "id": 19,
            "name": "Prefeitura de Camaçari"
        },
        {
            "id": 20,
            "name": "Prefeitura de Canavierias"
        },
        {
            "id": 21,
            "name": "Prefeitura de Chapadinha"
        },
        {
            "id": 22,
            "name": "Prefeitura de Corumbá"
        },
        {
            "id": 23,
            "name": "Prefeitura de Cuiabá"
        },
        {
            "id": 24,
            "name": "Prefeitura de Curaçá"
        },
        {
            "id": 25,
            "name": "Prefeitura de Custódia"
        },
        {
            "id": 26,
            "name": "Prefeitura de Duque de Caxias"
        },
        {
            "id": 27,
            "name": "Prefeitura de Embu PREV"
        },
        {
            "id": 28,
            "name": "Prefeitura de Embu das Artes"
        },
        {
            "id": 29,
            "name": "Prefeitura de Feira de Santana"
        },
        {
            "id": 30,
            "name": "Prefeitura Florianópolis"
        },
        {
            "id": 31,
            "name": "Prefeitura de Glória"
        },
        {
            "id": 32,
            "name": "Prefeitura de Glória de Goitá"
        },
        {
            "id": 33,
            "name": "Prefeitura de Goiânia"
        },
        {
            "id": 34,
            "name": "Prefeitura de Governador de Mangabeira"
        },
        {
            "id": 35,
            "name": "Prefeitura de Ibicuí"
        },
        {
            "id": 36,
            "name": "Prefeitura de Ilhéus"
        },
        {
            "id": 37,
            "name": "Prefeitura de Ipatinga"
        },
        {
            "id": 38,
            "name": "Prefeitura de Itanhém"
        },
        {
            "id": 39,
            "name": "Prefeitura de Itapetinga"
        },
        {
            "id": 40,
            "name": "Prefeitura de Itapissuma"
        },
        {
            "id": 41,
            "name": "Prefeitura de Ji Paraná"
        },
        {
            "id": 42,
            "name": "Prefeitura Jaboatão dos Guararapes"
        },
        {
            "id": 43,
            "name": "Prefeitura de Jacobina"
        },
        {
            "id": 44,
            "name": "Prefeitura de Jequié"
        },
        {
            "id": 45,
            "name": "Prefeitura de Jeremoabo"
        },
        {
            "id": 46,
            "name": "Prefeitura de Joao Pessoa"
        },
        {
            "id": 47,
            "name": "Prefeitura de Juazeiro"
        },
        {
            "id": 48,
            "name": "Prefeitura de Lauro de Freitas"
        },
        {
            "id": 49,
            "name": "Prefeitura Lençóis"
        },
        {
            "id": 50,
            "name": "Prefeitura de Limoeiro"
        },
        {
            "id": 51,
            "name": "Prefeitura de Macapá"
        },
        {
            "id": 52,
            "name": "Prefeitura de Mata de São João"
        },
        {
            "id": 53,
            "name": "Prefeitura de Monte Alegre de Sergipe"
        },
        {
            "id": 54,
            "name": "Prefeitura de Monção"
        },
        {
            "id": 55,
            "name": "Prefeitura de Nova Fátima"
        },
        {
            "id": 56,
            "name": "Prefeitura de Paudalho"
        },
        {
            "id": 57,
            "name": "Prefeitura de Planaltina de Goiás"
        },
        {
            "id": 58,
            "name": "Prefeitura de Porto Seguro"
        },
        {
            "id": 59,
            "name": "Prefeitura de Quixabeira"
        },
        {
            "id": 60,
            "name": "Prefeitura de Ruy Barbosa"
        },
        {
            "id": 61,
            "name": "Prefeitura de Salinas de Margarida"
        },
        {
            "id": 62,
            "name": "Prefeitura de Salvador"
        },
        {
            "id": 63,
            "name": "Prefeitura de Santanópolis"
        },
        {
            "id": 64,
            "name": "Prefeitura de Senhor do Bonfim"
        },
        {
            "id": 65,
            "name": "Prefeitura de Serrinha"
        },
        {
            "id": 66,
            "name": "Prefeitura de Surubim"
        },
        {
            "id": 67,
            "name": "Prefeitura de São José"
        },
        {
            "id": 68,
            "name": "Prefeitura de São Lourenço da Mata"
        },
        {
            "id": 69,
            "name": "Prefeitura de São Paulo"
        },
        {
            "id": 70,
            "name": "Prefeitura de Ubaitaba"
        },
        {
            "id": 71,
            "name": "Prefeitura de Uruçuca"
        },
        {
            "id": 72,
            "name": "Prefeitura de Valença"
        },
        {
            "id": 73,
            "name": "Prefeitura de Vitória da Conquista"
        },
        {
            "id": 74,
            "name": "Prefeitura de Vitória de Santo Antão"
        },
        {
            "id": 75,
            "name": "Prefeitura de Várzea da Roça"
        },
        {
            "id": 76,
            "name": "Tribunal de Contas do Município"
        },
        {
            "id": 77,
            "name": "Tribunal de Justiça da Bahia"
        },
        {
            "id": 78,
            "name": "Outro"
        }
    
];

export const conveniosConsorcios = [
    { id: 1, name: 'TRT 2° Região SP' },
    { id: 2, name: 'Prefeituras' },
    { id: 3, name: 'Governo Estadual' },
    { id: 4, name: 'Governo Federal Siape' },
    { id: 5, name: 'Polícia Civil' },
    { id: 6, name: 'Polícia Militar' },
    { id: 7, name: 'ADM Penitenciária' },
    { id: 8, name: 'Forças Armadas' },
    { id: 9, name: 'Poder Judiciário: TJ-TRT-TRF-TRE' },
    { id: 10, name: 'Ministérios Públicos' },
    { id: 11, name: 'Poder Legislativo: CD-SF-TCU-TCE-TCM-AL-CV' },
    { id: 12, name: 'CLT FED SIAPE/SIGEPE' },
    { id: 13, name: 'SENAC' },
    { id: 14, name: 'USP' },
    { id: 15, name: 'Outro Servidor Público' }
  ];