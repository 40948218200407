import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
// import { mask as masker, unMask } from "node-masker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";
import { handlePhone } from "../js/validate-phone-number";

const Withdraw = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const formType = "Quero antecipar meu FGTS";
  const formType1 = "Antecipar agora!"

  const [fieldValues, setFieldValues] = useState({});

  const handleChangeValues = (e) => {
    // Pega o name do input
    const fieldName = e.target.name;
    // Pega o valor do input
    const fieldValue = e.target.value;

    setFieldValues((current) => {
      return {
        ...current,
        [fieldName]: fieldValue,
      };
    });
  };

  const handleFormSubmitWithdrawalBirthday2 = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.SAQUE_ANIVERSARIO,
        name: fieldValues.name2,
        email: fieldValues.email2,
        phone: fieldValues.phone2,
        date_of_birth: fieldValues.dateBirth2,
        balance_fgts: fieldValues.balance2,
        formType
      });

      clearFieldValues(); // Chama a função para limpar os campos
    } catch (err) {
      console.error(err);
    }
  };

  const handleFormSubmitWithdrawalBirthday3 = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.SAQUE_ANIVERSARIO,
        name: fieldValues.name3,
        email: fieldValues.email3,
        phone: fieldValues.phone3,
        date_of_birth: fieldValues.dateBirth3,
        balance_fgts: fieldValues.balance3,
        formType1
      });

      clearFieldValues(); // Chama a função para limpar os campos
    } catch (err) {
      console.error(err);
    }
  };

  const clearFieldValues = () => {
    setFieldValues({
      name2: "",
      email2: "",
      phone2: "",
      dateBirth2: "",
      balance2: "",
      name3: "",
      email3: "",
      phone3: "",
      dateBirth3: "",
      balance3: "",


    });
  };
  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Dinheiro rápido e seguro na sua mão.
        // Antecipe até 10 parcelas do seu Saque-Aniversário!"
        background_image="saque1.png"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <ToastContainer />
        <div className="row">
          <div className="col-lg-8">
            <div className="col-12 mt-4 col-lg-12">
              <h1 className="mt-5 font_size_sm mb-5">Saque-Aniversário FGTS</h1>
              <h4 className="font-weight-bold text-justify mt-5 font_size_sm_h3 mb-lg-4 mb-xl-4">
                Com a Mais Valor você antecipa 10 anos do Saque-Aniversário do
                FGTS e recebe o dinheiro na conta em até 24 horas, sem comprometer sua renda mensal!
              </h4>
            </div>

            <div className="col-12 col-lg-12">
              <h3 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
                O que é a antecipação do Saque-Aniversário FGTS?
              </h3>

              <ul id="saque-aniversario-secao-01" className="list-unstyled">
                <li className="d-flex  align-items-start mb-3 mt-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    Uma nova modalidade de crédito destinada a pessoas físicas
                    que optarem pelo Saque-Aniversário do FGTS;
                  </span>
                </li>
                <li className="d-flex mb-3 align-items-start">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    Você pode antecipar até 10 anos do Saque-Aniversário, sem
                    comprometer suaincome mensal;
                  </span>
                </li>
                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2 ">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    É de R$ 100,00 e não há limite máximo para o saque;
                  </span>
                </li>

                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    Liberado para negativados com saldo no FGTS, mesmo em contas
                    inativas.
                  </span>
                </li>
                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    Débito anual diretamente do saldo do FGTS, sem descontar
                    parcelas mensais.
                  </span>
                </li>

                <button
                  type="button"
                  className="btn btn-success  mb-4 mt-4 hover_products border_radius_btn text-decoration-none"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  Quero antecipar meu FGTS!
                </button>

                <div
                  className="modal fade"
                  id="exampleModal1"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title mx-auto text-uppercase"
                          id="exampleModalLabel1"
                        >
                          Quero antecipar meu FGTS!
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <h6
                        className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                        id="exampleModalLabel"
                      >
                        Dados Pessoais
                      </h6>
                      <div className="modal-body">
                        <form
                          id="form_antecipar_fgts"
                          name="form_antecipar_fgts"
                          onSubmit={handleFormSubmitWithdrawalBirthday2}
                        >
                          <div className="d-lg-flex gap-lg-3">
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text"
                                htmlFor="nome_antecipar_fgts"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Digite seu nome"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="nome_antecipar_fgts"
                                name="name2"
                                value={fieldValues.name2 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text"
                                htmlFor="email_antecipar_fgts"
                              >
                                E-mail
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Digite seu e-mail"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="email_antecipar_fgts"
                                name="email2"
                                value={fieldValues.email2 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                          </div>

                          <div className="d-lg-flex gap-lg-3">
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text"
                                htmlFor="phone_antecipar_fgts"
                              >
                                Celular
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Digite seu número"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="phone_antecipar_fgts"
                                name="phone2"
                                maxLength="15"
                                onKeyUp={(e) => handlePhone(e)}
                                value={fieldValues.phone2 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text_fgts"
                                htmlFor="cpf_antecipar_fgts"
                              >
                                Data de nasc.
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Digite sua data de nascimento"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="cpf_antecipar_fgts"
                                name="dateBirth2"
                                value={fieldValues.dateBirth2 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                          </div>

                          <div className="">
                            <p className=" mb-lg-0 mt-lg-1">
                              Você tem saldo FGTS?
                            </p>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input sexo"
                                id="fgts"
                                value="SIM"
                                name="balance2"
                                onChange={handleChangeValues}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="fgts"
                              >
                                Sim
                              </label>
                            </div>
                            <div className="custom-control mb-2 custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input sexo"
                                id="fgts1"
                                value="SIM"
                                name="balance2"
                                onChange={handleChangeValues}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="fgts1"
                              >
                                Não
                              </label>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Fechar
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Enviar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>

            <div className="col-12 col-lg-12">
              <h3 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
                Antecipe o seu FGTS com a Mais Valor e aproveite todas as
                vantagens!
              </h3>

              <ul id="saque-aniversario-secao-01" className="list-unstyled">
                <li className="d-flex align-items-start mt-3 mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    Dinheiro na conta em até 24h úteis após aprovação.
                  </span>
                </li>
                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Sem descontos de parcelas mensais.</span>
                </li>

                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Crédito autorizado para pessoas negativadas.</span>
                </li>

                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>
                    Não impacta na contratação de outras linhas de crédito.
                  </span>
                </li>

                <li className="d-flex align-items-start mb-3 ">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Débito direto na conta vinculada ao FGTS.</span>
                </li>

                <li className="d-flex  align-items-start mb-3 ">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Menor taxa do mercado.</span>
                </li>

                <button
                  type="button"
                  className="btn btn-success  mb-4 mt-4 hover_products border_radius_btn text-decoration-none"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  Antecipar agora!
                </button>

                <div
                  className="modal fade"
                  id="exampleModal2"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title mx-auto text-uppercase"
                          id="exampleModalLabel2"
                        >
                          Antecipar agora!
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <h6
                        className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                        id="exampleModalLabel"
                      >
                        Dados Pessoais
                      </h6>
                      <div className="modal-body">
                        <form
                          id="form_antecipar_agora"
                          name="form_antecipar_agora"
                          onSubmit={handleFormSubmitWithdrawalBirthday3}
                        >
                          <div className="d-lg-flex gap-lg-3">
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text"
                                htmlFor="nome_antecipar_fgts"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Digite seu nome"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="nome_antecipar_fgts"
                                name="name3"
                                value={fieldValues.name3 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text"
                                htmlFor="email_antecipar_fgts"
                              >
                                E-mail
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Digite seu e-mail"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="email_antecipar_fgts"
                                name="email3"
                                value={fieldValues.email3 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                          </div>
                          <div className="d-lg-flex gap-lg-3">
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text"
                                htmlFor="phone_antecipar_fgts"
                              >
                                Celular
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Digite seu número"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="phone_antecipar_fgts"
                                name="phone3"
                                maxLength="15"
                                value={fieldValues.phone3 || ""}
                                onChange={handleChangeValues}
                                onKeyUp={(e) => handlePhone(e)}
                              />
                            </div>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-Text_fgts"
                                htmlFor="cpf_antecipar_fgts"
                              >
                                Data de nasc.
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Digite sua data de nascimento"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="cpf_antecipar_fgts"
                                name="dateBirth3"
                                value={fieldValues.dateBirth3 || ""}
                                onChange={handleChangeValues}
                              />
                            </div>
                          </div>
                          <div className="">
                            <p className=" mb-lg-0 mt-lg-1">
                              Você tem saldo FGTS?
                            </p>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input sexo"
                                id="antecipar"
                                name="balance3"
                                value="SIM"
                                onChange={handleChangeValues}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="antecipar"
                              >
                                Sim
                              </label>
                            </div>
                            <div className="custom-control mb-2 custom-radio custom-control-inline">
                              <input
                                type="radio"
                                className="custom-control-input sexo"
                                id="antecipar1"
                                name="balance3"
                                value="NAO"
                                onChange={handleChangeValues}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="antecipar1"
                              >
                                Não
                              </label>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Fechar
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Enviar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <div className="col-12 col-lg-12">
              <h3 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
                Quem pode antecipar?
              </h3>

              <ul id="saque-aniversario-secao-01" className="list-unstyled">
                <li className="d-flex align-items-start mb-3 mt-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Maiores de 18 anos.</span>
                </li>

                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Ter trabalhado com carteira assinada.</span>
                </li>
                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Optou pelo Saque-Aniversário.</span>
                </li>
                <li className="d-flex align-items-start mb-3">
                  <i className="text-success mr-2">
                    <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                  </i>
                  <span>Mínimo R$ 100 de saldo no FGTS.</span>
                </li>
              </ul>
            </div>

            <div className="faq-list p-3  bg-opacity-10   rounded-end ">
              <h3 className="font-weight-bold font_size_sm mb-0 mb-lg-3 mb-xl-2">
                Ainda com dúvida? Confira algumas perguntas frequentes!
              </h3>
              <ul className="list-unstyled">
                <li data-aos="fade-up " data-aos-delay="100">
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed color_sobre"
                    onClick={() => setOpen(!open)}
                  >
                    - O que é FGTS?{" "}
                    <i className="fa icon-arrow-collapsed  mt-3 mb-3"></i>
                  </a>
                  <Collapse in={open}>
                    <p>
                      O FGTS — Fundo de Garantia do Tempo de Serviço — é um
                      fundo que todo trabalhador com carteira assinada tem
                      direito na Caixa Econômica Federal em que a empresa
                      empregadora deposita mensalmente uma porcentagem do
                      salário bruto de cada funcionário. Esse fundo pode ter
                      contas do tipo ativa (referente ao emprego atual do
                      trabalhador) ou inativa (referente ao valor acumulado em
                      empregos anteriores).
                    </p>
                  </Collapse>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed color_sobre "
                    onClick={() => setOpen1(!open1)}
                  >
                    - O que é Saque-Aniversário?{" "}
                    <i className="fa icon-arrow-collapsed mb-3"></i>
                  </a>
                  <Collapse in={open1}>
                    <p>
                      Todos os anos, no mês de aniversário, trabalhadores com
                      carteira assinada têm a opção de receber parte do saldo
                      disponível no FGTS.
                    </p>
                  </Collapse>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed color_sobre "
                    onClick={() => setOpen2(!open2)}
                  >
                    - Como me cadastrar no Saque-Aniversário?{" "}
                    <i className="fa icon-arrow-collapsed mb-3"></i>
                  </a>
                  <Collapse in={open2}>
                    <p>
                      Primeiro você deve entrar no site do FGTS ou aplicativo do
                      FGTS, e na tela inicial clicar na opção “Se cadastrar na
                      modalidade Saque-Aniversário”. Feito isso, você insira seu
                      CPF e senha - a mesma utilizada para consultar o extrato
                      do fundo de garantia. Em seguida aparecerá uma tela com as
                      informações da modalidade em qual o trabalhador se
                      encontra, geralmente saque-rescisão. Clique na opção
                      “saque-aniversário”, depois escolha qual data receber o
                      dinheiro. Pode ser no 1° dia do mês de aniversário ou 10°
                      dia do mês seguinte.
                    </p>
                  </Collapse>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed color_sobre "
                    onClick={() => setOpen3(!open3)}
                  >
                    - Como eu recebo o valor do adiantamento do FGTS?{" "}
                    <i className="fa icon-arrow-collapsed mb-3"></i>
                  </a>
                  <Collapse in={open3}>
                    <p>Diretamente na conta corrente do trabalhador.</p>
                  </Collapse>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-6"
                    className="collapsed color_sobre "
                    onClick={() => setOpen4(!open4)}
                  >
                    - Quem pode antecipar o FGTS com a Mais Valor?{" "}
                    <i className="fa icon-arrow-collapsed mb-3"></i>
                  </a>
                  <Collapse in={open4}>
                    <p>
                      Maiores de 18 anos, que já tenham trabalhado com carteira
                      assinada, que optem pela opção Saque-Aniversário e com
                      pelo menos R$ 400 de saldo no FGTS.
                    </p>
                  </Collapse>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    href="/"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-7"
                    className="collapsed color_sobre "
                    onClick={() => setOpen5(!open5)}
                  >
                    - Tenho restrição no nome, posso antecipar meu FGTS com a
                    Mais Valor? <i className="fa icon-arrow-collapsed mb-3"></i>
                  </a>
                  <Collapse in={open5}>
                    <p>
                      Sim! Essa alternativa de crédito é permitida mesmo para
                      quem tem restrições no CPF.
                    </p>
                  </Collapse>
                </li>
              </ul>
            </div>

            <div className="col-12 col-lg-12">
              <h3 className="font-weight-bold font_size_sm mb-0 mt-4 mb-lg-3 mb-xl-2">
                Não deixe o seu dinheiro parado no banco.
                <br />
                Faça a sua antecipação com a Mais Valor!
              </h3>

              <a
                href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                  5511913675781
                )}`}
                className={`btn btn-success mt-lg-3 mb-lg-3 mb-lg-1 mt-3 mb-3 hover_products border_radius_btn text-decoration-none`}
                target="blank"
              >
                Quero falar com um consultor
              </a>
            </div>
          </div>
        </div>
      </div>

      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

export default Withdraw;
