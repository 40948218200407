import React, { useState } from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import { mask as masker, unMask } from "node-masker";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";
import { convenios } from "../utils/lista-de-convenio";

const Benefit = () => {
  const [phone, setPhone] = useState("");
  // const [cpf, setCpf] = useState("");
  // const cpf_pattern = "999.999.999-99";
  const phone_pattern = "(99) 99999-9999";
  const formType = "CARTÃO BENEFÍCIO";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [benefit, setBenefit] = useState(null);

  const handleSubmitBenefit = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.BENEFIT,
        name,
        email,
        phone,
        benefit,
        formType
      });
      handleResetForm();
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetForm = () => {
    setPhone("");  
    setName("");
    setEmail("");
    setBenefit("");
  };

  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Consórcio: uma maneira segura e econômica de conquistar seus sonhos."
        background_image="beneficio1.png"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-5 font_size_sm mb-5">Cartão Benefício</h1>
            <h2 className="mb-4 font_size_sm_h3 mt-4">
              Atenção, servidor público
            </h2>
            <p className="mb-5">
              O cartão benefício é uma facilidade que chegou para te ajudar nas
              finanças. Ele conta com Saque Fácil, que é uma linha de crédito em
              que você pode sacar a qualquer momento, mesmo sem ter saldo em
              conta. O valor cai em até duas horas após a solicitação.
              <br />
              Você pode solicitar o Saque Fácil através de diversos canais: app,
              site, Central de Relacionamento ou lojas físicas. O valor é
              creditado na conta corrente e o pagamento pode ser parcelado (com
              juros).
            </p>

            <h3 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
              Separamos algumas vantagens do cartão benefício para você:
            </h3>

            <ul id="saque-aniversario-secao-01" className="list-unstyled">
              <li className="d-flex  align-items-start mb-3 mt-3">
                <img
                  className=" mr-1 mt-1 "
                  alt="mensagem"
                  src={
                    images["icons8-parte-traseira-de-cartão-de-banco-20.png"]
                  }
                />{" "}
                <span>Saque Fácil não rotativo</span>
              </li>

              <li className="d-flex align-items-start mb-3">
                <img
                  className=" mr-1 mt-1 "
                  alt="mensagem"
                  src={
                    images["icons8-parte-traseira-de-cartão-de-banco-20.png"]
                  }
                />{" "}
                <span>Contratação fácil</span>
              </li>
              <li className="d-flex align-items-start mb-3">
                <img
                  className=" mr-1 mt-1 "
                  alt="mensagem"
                  src={
                    images["icons8-parte-traseira-de-cartão-de-banco-20.png"]
                  }
                />{" "}
                <span>Sem taxa de serviço</span>
              </li>
              <li className="d-flex align-items-start mb-3">
                <img
                  className=" mr-1 mt-1 "
                  alt="mensagem"
                  src={
                    images["icons8-parte-traseira-de-cartão-de-banco-20.png"]
                  }
                />{" "}
                <span>Cartão sem anuidade</span>
              </li>
              <li className="d-flex align-items-start mb-4">
                <img
                  className=" mr-1 mt-1 "
                  alt="mensagem"
                  src={
                    images["icons8-parte-traseira-de-cartão-de-banco-20.png"]
                  }
                />{" "}
                <span>Sem taxa de emissão</span>
              </li>
            </ul>

            <button
              type="button"
              className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Contratar agora
            </button>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title mx-auto text-uppercase"
                      id="exampleModalLabel"
                    >
                      Cartão Benefício!
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <h6
                    className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                    id="exampleModalLabel"
                  >
                    Dados Pessoais
                  </h6>
                  <div className="modal-body ">
                    <form
                      id="form_consorcio"
                      name="form_consorcio"
                      onSubmit={handleSubmitBenefit}
                    >
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="nome_benefit"
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Digite seu nome"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="nome_benefit"
                          name="nome_benefit"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="email_benefit"
                        >
                          E-mail
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="email_benefit"
                          name="email_benefit"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="phone_benefit"
                        >
                          Celular
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Digite seu número"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="phone_consorcio"
                          name="phone_consorcio"
                          onChange={({ target }) =>
                            setPhone(unMask(target.value))
                          }
                          value={masker(phone, phone_pattern)}
                        />
                      </div>
                      <div className="d-lg-flex justify-content-between px-lg-3">
                        <div className="">
                          <p className=" mb-lg-0  mt-lg-1">
                            Convênio cartão benefício
                          </p>
                          <select
                            className="form-select form-select-sm mb-3"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setBenefit(e.target.value)}
                            value={benefit}
                          >
                            <option selected >
                              {" "}
                              [Selecione]
                            </option>

                            {convenios.map(({ id, name }) => {
                              return <option value={name} key={id}>{name}</option>
                            })}

                          </select>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Enviar
                        </button>
                      </div>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);

export default Benefit;
