import React, { useState } from "react";

import Header from "../components/Header";
import Amazing from "../components/Amazing";
import "react-toastify/dist/ReactToastify.css";

const CgiPessoal = () => {
  // credito imovel
  const [message, setMessage] = useState("");
  const [money, setMoney] = useState("R$ 30.000,00");
  const [linkActive, setLinkActive] = useState("");
  const [inputMoney, setInputMoney] = useState("30000");
  const [whatsappMessage, setWhatsappMessage] = useState("");

  //credito pessoal
  const [linkPersonal, setLinkPersonal] = useState("");
  const [messagePersonal, setMessagePersonal] = useState("");
  const [moneyPersonal, setMoneyPersonal] = useState("R$ 500,00");
  const [inputPersonal, setInputPersonal] = useState("500");
  const [whatsappPersonal, setWhatsappPersonal] = useState("");

  //credito consignado
  const [linkConsig, setLinkConsig] = useState("");
  const [messageConsig, setMessageConsig] = useState("");
  const [moneyConsig, setMoneyConsig] = useState("R$ 500,00");
  const [inputConsig, setInputConsig] = useState("500");
  const [whatsappConsig, setWhatsappConsig] = useState("");

  const handleSimulation = () => {
    var valueSimulator = money.replace(/\D/g, "");
    var message = `Quero contratar CGI no valor ${formatMoney(
      valueSimulator
    )} `;
    setWhatsappMessage(message);
  };

  /**
   * @param event onChange
   * handleUpdateRawValue => atualiza e devolve o valor formatado
   */
  const handleUpdateRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = formatMoney(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoney(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      innerMessage(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setInputMoney("30000");
    } else {
      setInputMoney(newValue);
    }
  };

  const formatMoney = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };

  const innerMessage = (value) => {
    if (parseInt(value) < 30000) {
      setLinkActive("disabled");
      return setMessage(`Valor mínimo: R$ 30.000,00`);
    } else {
      setLinkActive("");
      setMessage("");
    }

    if (parseInt(value) > 20000000) {
      setLinkActive("disabled");
      setMessage("Valor máximo: R$ 20.000.000,00");
      return;
    }
    setLinkActive("");
    setMessage("");
  };

  const handleValueChange = (e) => {
    let inputValue = e.target.value;
    // Remover caracteres não numéricos
    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue === "") {
      inputValue = "0";
    }

    // Limitar o valor máximo a 20 milhões
    const maxValue = 2000000000;
    const numericValue = parseInt(inputValue, 10);
    const limitedValue = numericValue > maxValue ? maxValue : numericValue;

    // Formatar o valor
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(limitedValue / 100);
  };

  // credito pessoal
  const simulationhandle = () => {
    var valueSimulator = moneyPersonal.replace(/\D/g, "");
    var messagePersonal = `Quero contratar um crédito pessoal no valor ${moneyFormat(
      valueSimulator
    )} `;
    setWhatsappPersonal(messagePersonal);
  };
  const updateHandleRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = moneyFormat(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoneyPersonal(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      messageInner(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setMoneyPersonal("500");
    } else {
      setMoneyPersonal(newValue);
    }
  };
  const moneyFormat = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };
  const messageInner = (value) => {
    if (parseInt(value) < 500) {
      setLinkPersonal("disabled");
      return setMessagePersonal(`Valor mínimo: R$ 500,00`);
    } else {
      setLinkPersonal("");
      setMessagePersonal("");
    }

    if (parseInt(value) > 30000) {
      setLinkPersonal("disabled");
      setMessagePersonal("Valor máximo: R$ 30.000,00");
      return;
    }
    setLinkPersonal("");
    setMessagePersonal("");
  };

  // Credito Consignado
  const handleSimulationConsig = () => {
    var valueSimulator = moneyConsig.replace(/\D/g, "");
    var messageConsig = `Quero contratar Consignado no valor ${formatMoneyConsig(
      valueSimulator
    )} `;
    setWhatsappConsig(messageConsig);
  };
  const consigHandleUpdateRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = formatMoneyConsig(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoneyConsig(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      innerMessageConsig(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setMoneyConsig("500");
    } else {
      setMoneyConsig(newValue);
    }
  };
  const formatMoneyConsig = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };

  const innerMessageConsig = (value) => {
    if (parseInt(value) < 500) {
      setLinkConsig("disabled");
      return setMessageConsig(`Valor mínimo: R$ 500,00`);
    } else {
      setLinkConsig("");
      setMessageConsig("");
    }

    if (parseInt(value) > 500000) {
      setLinkConsig("disabled");
      setMessageConsig("Valor máximo: R$ 500.000,00");
      return;
    }
    setLinkConsig("");
    setMessageConsig("");
  };

  return (
    <div className="App">
      {/* <Menu menu={Menu}></Menu> */}
      <Header
        // intro="Crédito Imobiliário"
        className="margin_top_smm intro-texttt"
        background_image="consignado1.jpg"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <a
          href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
            5511913675781
          )}`}
          className={`btn btn-success mt-lg-5 mb-lg-4 hover_products border_radius_btn text-decoration-none `}
          target="blank"
        >
          Fale com o consultor
        </a>

        <h2 className="mt-3">Sobre Nós</h2>
        <p>
          A Mais Valor é uma das maiores gestoras de negócios, especializada em
          consultoria financeira para pessoas físicas, com salas de negócios e
          parceiros distribuídos pelos principais estados do Brasil.
        </p>
        <div className="row">
          <div className="">
            <h3 className="mt-4 font_size_sm mb-4">Crédito Consignado</h3>
            <div className="row">
              <div className="col-sm-4 mb-5 mb-lg-0 d-flex align-items-stretch">
                <img
                  src={images["consig1.png"]}
                  alt="Sua Imagem"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-4 margin_bottom_estate px-2 d-flex align-items-stretch">
                <div className="card card-container-landingpage h-100">
                  <div className="card-body">
                    <h5 className="card-title">Eu preciso de:</h5>
                    <div className="rangers">
                      <div className="input-range">
                        <input
                          type="range"
                          name=""
                          id="max"
                          min="500"
                          max="500000"
                          maxLength="12"
                          onChange={(e) => {
                            setMoneyConsig(
                              new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(e.target.value)
                            );
                            setInputConsig(e.target.value);
                          }}
                          value={inputConsig}
                        />
                        <input
                          className="font_size_cosignado"
                          type="text"
                          inputMode="numeric"
                          maxLength="16"
                          onChange={consigHandleUpdateRawValue}
                          value={moneyConsig}
                        />
                      </div>
                    </div>
                    <span className="d-lg-flex text-danger my-1">
                      {messageConsig}
                    </span>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>
                          <i>Min:</i>
                        </span>
                        <p>R$ 500,00</p>
                      </div>
                      <div>
                        <span>
                          <i>Max:</i>
                        </span>
                        <p>R$ 500.000,00</p>
                      </div>
                    </div>
                    <a
                      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                        5511913675781
                      )}&text=${encodeURIComponent(whatsappConsig)}`}
                      className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkConsig}`}
                      onClick={handleSimulationConsig}
                      target="blank"
                    >
                      Quero falar com um consultor
                    </a>
                    <p className="text-secondary">
                      <small>
                        *Horário de atendimento: seg a sex, das 9h às 18h.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 margin_bottom_estate px-2 d-flex align-items-stretch">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">
                      As principais vantagens do empréstimo consignado!
                    </h5>
                    <p className="card-text mb-4">
                      Incluem <strong>taxas de juros mais baixas</strong> em
                      comparação com outras formas de crédito.
                    </p>
                    <p className="card-text mb-5">
                      Devido à maior garantia de pagamento, e a facilidade de
                      obtenção, pois a análise de crédito muitas vezes é
                      simplificada.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="">
            <h3 className="mt-4 font_size_sm mb-4">Crédito Pessoal</h3>
            <div className="row">
              <div className="col-sm-4 mb-5 mb-lg-0 d-flex align-items-stretch">
                <img
                  src={images["Pessoallll.png"]}
                  alt="Sua Imagem"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-4 margin_bottom_estate px-2 d-flex align-items-stretch">
                <div className="card card-container-landingpage h-100">
                  <div className="card-body">
                    <h5 className="card-title">Vantagens do crédito pessoal</h5>
                    <p className="card-text mb-4">
                      É uma forma de empréstimo oferecida para atender a
                      necessidades financeiras pessoais.
                    </p>
                    <p className="card-text mb-5">
                      Este tipo de crédito é caracterizado pela flexibilidade no
                      uso dos recursos, pois não é vinculado a uma finalidade
                      específica, como a compra de um carro ou casa.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 margin_bottom_estate px-2 d-flex align-items-stretch">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">Eu preciso de:</h5>
                    <div className="rangers">
                      <div className="input-range">
                        <input
                          type="range"
                          name=""
                          id="max"
                          min="500"
                          max="30000"
                          maxLength="12"
                          onChange={(e) => {
                            setMoneyPersonal(
                              new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(e.target.value)
                            );
                            setInputPersonal(e.target.value);
                          }}
                          value={inputPersonal}
                        />
                        <input
                          className="font_size_cosignado"
                          type="text"
                          inputMode="numeric"
                          maxLength="16"
                          onChange={updateHandleRawValue}
                          value={moneyPersonal}
                        />
                      </div>
                    </div>
                    <span className="d-lg-flex text-danger my-1">
                      {messagePersonal}
                    </span>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>
                          <i>Min:</i>
                        </span>
                        <p>R$ 500,00</p>
                      </div>
                      <div>
                        <span>
                          <i>Max:</i>
                        </span>
                        <p>R$ 30.000,00</p>
                      </div>
                    </div>
                    <a
                      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                        5511913675781
                      )}&text=${encodeURIComponent(whatsappPersonal)}`}
                      className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkPersonal}`}
                      onClick={simulationhandle}
                      target="blank"
                    >
                      Quero falar com um consultor
                    </a>
                    <p className="text-secondary">
                      <small>
                        *Horário de atendimento: seg a sex, das 9h às 18h.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="">
            <h3 className="font_size_sm mb-4 mt-lg-5">
              Crédito com Garantia de Imóvel
            </h3>

            <div className="row">
              <div className="col-sm-4 mb-5 mb-lg-0 d-flex align-items-stretch">
                <img
                  src={images["CGI.png"]}
                  alt="Sua Imagem"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-4 margin_bottom_estate px-2 d-flex align-items-stretch">
                <div className="card card-container-landingpage h-100">
                  <div className="card-body">
                    <h5 className="card-title">
                      Empréstimo com garantia de imóvel
                    </h5>
                    <p className="card-text mb-4">
                      Use seu imóvel para ter as{" "}
                      <strong>menores taxas de juros.</strong>
                    </p>
                    <p className="card-text mb-5">
                      Só com a Mais Valor você tem até 240 meses para pagar e
                      uma consultoria financeira personalizada.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 margin_bottom_estate px-2 d-flex align-items-stretch">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">Eu preciso de:</h5>
                    <div className="rangers">
                      <div className="input-range ">
                        <input
                          type="range"
                          name=""
                          id="max"
                          min="30000"
                          max="20000000"
                          maxLength="12"
                          onChange={(e) => {
                            setMoney(
                              new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(e.target.value)
                            );
                            setInputMoney(e.target.value);
                          }}
                          value={inputMoney}
                        />
                        <input
                          className="fs-4"
                          type="text"
                          inputMode="numeric"
                          maxLength="16"
                          onChange={handleUpdateRawValue}
                          value={money}
                        />
                      </div>
                    </div>
                    <span className="d-lg-flex text-danger my-1">
                      {message}
                    </span>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>
                          <i>Min:</i>
                        </span>
                        <p>R$ 30.000,00</p>
                      </div>
                      <div>
                        <span>
                          <i>Max:</i>
                        </span>
                        <p>R$ 20.000.000,00</p>
                      </div>
                    </div>
                    <a
                      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                        5511913675781
                      )}&text=${encodeURIComponent(whatsappMessage)}`}
                      className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkActive}`}
                      onClick={handleSimulation}
                      target="blank"
                    >
                      Quero falar com um consultor
                    </a>
                    <p className="text-secondary">
                      <small>
                        *Horário de atendimento: seg a sex, das 9h às 18h.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className="section-main-numbers bg-numbers ">
        <div className="container">
          <div
            className="col-md-12 text-center aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            <h2 className="main-title-desktop mb-3 mt-5 color_sobre main-title-mobile mb-lg-5 text-black pb-30">
              Nossos números
            </h2>
          </div>
          <div className="counters d-flex justify-content-center align-items-center flex-wrap flex-md-nowrap color_sobre">
            <div
              className="col-lg-3 col-md-6 text-center mb-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number mt-lg-3 mb-4">
                <img
                  src={images["icons8-brasilll-100.png"]}
                  width="100="
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number">
                <h2 className="number fs-4">
                  + 13 anos<span> atendendo</span>
                  <br></br>clientes de todo o Brasil
                </h2>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 text-center mb-5 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number mt-lg-3 mb-4">
                <img
                  src={images["icons8-grupos-de-usuários-100.png"]}
                  width="100"
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number  ">
                <h2 className="number fs-4">
                  + 1.5 Milhões{" "}
                  <span>
                    {" "}
                    <br></br>de clientes
                  </span>
                </h2>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 mb-5 text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number d mt-lg-3 mb-4">
                <img
                  src={images["icons8-carteira-100.png"]}
                  width="100"
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number">
                <h2 className="number fs-4">
                  10 <span>soluções</span>{" "}
                  <span>
                    <br></br>de crédito
                  </span>
                </h2>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 mb-5 text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number d mt-lg-3 mb-4">
                <img
                  src={images["icons8-gráfico-100.png"]}
                  width="100"
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number">
                <h2 className="number fs-4">
                  Uma das<span> maiores</span>{" "}
                  <span>
                    <br></br>gestoras de negócios do país
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2023 - Aqui + Valor Negocio Promocoes e Intermediacoes LTDA / CNPJ/MF
        n° 15.359.515/0001-50
      </div>
    </div>
  );
};

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);

export default CgiPessoal;
