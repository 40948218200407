
export const PRODUCTS = {
  "CREDITO_PESSOAL": 1,
  "CREDITO_IMOBILIARIO": 2,
  // "FINANCIAMENTO_IMOBILIARIO": 2,
  "CREDITO_VEICULO": 3,
  "PORTABILIDADE_CREDITO": 4,
  "GARANTIA_IMOVEL": 5,
  "CONSORCIO": 6,
  "EMPRESTIMO_CONSIGNADO": 7,
  "SEM_BUROCRACIA": 8,
  "SIMULE_SEM": 9,
  "CARTAO_BENEFICIOS": 10,
  "BENEFIT": 11,
  "SAQUE_ANIVERSARIO": 12,
  "PORTABILIDADE_EMPRESTIMO": 13,
}
