import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";


const Products = () => {

  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Precisando de dinheiro? Nós temos a solução de crédito ideal para você!"
        background_image="produtos.png"
        // subintro=""
        header={Header}
      ></Header>
      <div className="container  color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mb-5 font_size_sm mt-5">Produtos</h1>
            <h2 className="mb-4 font_size_sm_h3 mt-4">
              Mais Valor para você e sua família!
            </h2>
            <p className="mb-5">
              Oferecemos as melhores opções de créditos e consórcios de forma
              prática e segura. Taxas atrativas que estão entre as menores do
              mercado. Entre em contato com a gente e fale com nossos
              especialistas!
            </p>

            <h3 className="mb-4 font_size_sm">
              Nós temos as melhores condições para:
            </h3>

            <ul className="list-unstyled font-weight-bold">
              <li className="mb-3 ">
                <a href="https://fgts.grupomaisvalor.com.br/" target="_blank" rel="noopener noreferrer">
                  Antecipação Saque-Aniversário FGTS
                </a>
              </li>
              <li className="mb-3">
                <Link to="/cartaobeneficio">Cartão Benefício</Link>
              </li>
              <li className="mb-3">
                <Link to="/consorcios">Consórcio</Link>
              </li>
              <li className="mb-3">
                <Link to="/creditocomgarantiadeimovel">
                  Crédito com Garantia de Imóvel
                </Link>
              </li>
              <li className="mb-3">
                <Link to="/creditocomgarantiadeveiculo">
                  Crédito com Garantia de Veículo
                </Link>
              </li>

              <li className="mb-3">
                <Link to="/creditoconsignado">Crédito Consignado</Link>
              </li>
              <li className="mb-3">
                <Link to="/creditopessoal">Crédito Pessoal</Link>
              </li>

              <li className="mb-3">
                <Link to="/portabilidadeemprestimo">
                  Portabilidade de Empréstimo
                </Link>
              </li>

              <li className="mb-3">
                <a href="https://bolsafamilia.grupomaisvalor.com.br/" target="blank">
                  Bolsa Família
                </a>
              </li>

              <li className="mb-3">
                <a href="https://servidorpublico.grupomaisvalor.com.br/" target="blank">
                  Sevidor Público
                </a>
              </li>
            </ul>
            <br></br>
          </div>
        </div>

        <Link
          className=" btn btn-success mb-4 hover_products border_radius_btn text-decoration-none"
          to="/contatos"
        >
          Fale conosco
        </Link>
      </div>
      {/* <div
        className="modal fade"
        id="modal_mensagem_saida"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content margi_top_simule">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">
                Simule sem compromisso!
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="form_simulacao" name="form_simulacao">
                <div className="input-group mb-3">
                  <label className="input-group-Text" htmlFor="nome_simulacao">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Digite seu nome"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="nome_simulacao"
                    name="nome_simulacao"
                  />
                </div>
                <div className="input-group mb-3">
                  <label
                    className="input-group-Text"
                    htmlFor="email_portabilidade"
                  >
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Digite seu e-mail"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="email_simulacao"
                    name="email_simulacao"
                  />
                </div>
                <div className="input-group mb-3">
                  <label
                    className="input-group-Text"
                    htmlFor="phone_portabilidade"
                  >
                    TEL
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Digite seu phone"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="phone_simulacao"
                    name="phone_simulacao"
                    onChange={({ target }) => setPhone(unMask(target.value))}
                    value={masker(phone, phone_pattern)}
                  />
                </div>
               
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Solicitar simulação
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

export default Products;
