import React, { useState } from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import { mask as masker, unMask } from "node-masker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";
import { conveniosConsorcios } from "../utils/lista-de-convenio";

const Credit = () => {
  const [message, setMessage] = useState("");
  const [money, setMoney] = useState("R$ 500,00");
  const [linkActive, setLinkActive] = useState("");
  const [inputMoney, setInputMoney] = useState("500");
  const formType = "CRÉDITO CONSIGNADO";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState("");
  const phone_pattern = "(99) 99999-9999";

  // Em qual tipo de instituição você trabalha?
  const [typeInss, setTypeInss] = useState("");
  // Selecione o convênio:
  const [insurance, setInsurance] = useState("");

  // Aposentado ou pensionista do INSSS
  const [retiredPensioner, setRetiredPensioner] = useState("");

  const [whatsappMessage, setWhatsappMessage] = useState("");

  const handleSubmitConsignedCredit = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.CONSORCIO,
        name,
        email,
        phone,
        typeInss,
        insurance,
        retiredPensioner,
        formType,
      });
      handleResetForm();
    } catch (err) {
      console.error(err);
    }
  };

  const handleSimulation = () => {
    var valueSimulator = money.replace(/\D/g, "");
    var message = `Quero contratar Consignado no valor ${formatMoney(
      valueSimulator
    )} `;
    setWhatsappMessage(message);
  };
  const handleUpdateRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = formatMoney(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoney(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      innerMessage(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setInputMoney("500");
    } else {
      setInputMoney(newValue);
    }
  };
  const formatMoney = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };

  const innerMessage = (value) => {
    if (parseInt(value) < 500) {
      setLinkActive("disabled");
      return setMessage(`Valor mínimo: R$ 500,00`);
    } else {
      setLinkActive("");
      setMessage("");
    }

    if (parseInt(value) > 500000) {
      setLinkActive("disabled");
      setMessage("Valor máximo: R$ 500.000,00");
      return;
    }
    setLinkActive("");
    setMessage("");
  };

  const handleResetForm = (e) => {
    // setCpf("")
    setPhone("");
    setName("");
    setEmail("");
    setInsurance("");
    setRetiredPensioner("");
    setTypeInss("");
  };



  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Crédito Consignado com mais praticidade para você cuidar do que mais importa!"
        background_image="consignado1.jpg"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-4 font_size_sm mb-4">Crédito Consignado</h1>

            <div className="col-sm-6 margin_bottom_estate px-2">
              <div className="card ">
                <div className="card-body ">
                  <h5 className="card-title">Eu preciso de:</h5>
                  <div className="rangers">
                    <div className="input-range ">
                      <input
                        type="range"
                        name=""
                        id="max"
                        min="500"
                        max="500000"
                        maxLength="12"
                        onChange={(e) => {
                          setMoney(
                            new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(e.target.value)
                          );
                          setInputMoney(e.target.value);
                        }}
                        value={inputMoney}
                      />
                      <input
                        className="font_size_cosignado"
                        type="text"
                        inputMode="numeric"
                        maxLength="16"
                        onChange={handleUpdateRawValue}
                        value={money}
                      />
                    </div>
                  </div>
                  <span className="d-lg-flex text-danger my-1">{message}</span>

                  <br></br>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span>
                        <i>Min:</i>
                      </span>
                      <p>R$ 500,00</p>
                    </div>

                    <div>
                      <span>
                        <i>Max:</i>
                      </span>
                      <p>R$ 500.000,00</p>
                    </div>
                  </div>

                  {/* <Link
                      to="/simuladorimovel"
                      className={
                        "btn btn-success mt-3 hover_products border_radius_btn text-decoration-none"
                      }
                      target="blank"
                    >
                      Quero fazer uma simulação
                    </Link> */}
                  <a
                    href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                      5511913675781
                    )}&text=${encodeURIComponent(whatsappMessage)}`}
                    className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkActive}`}
                    onClick={handleSimulation}
                    target="blank"
                  >
                    Quero falar com um consultor
                  </a>
                  <p className="text-secondary">
                    <small>
                      *Horário de atendimento: seg a sex, das 9h às 18h.
                    </small>
                  </p>
                </div>
              </div>
            </div>

            <h2 className="mb-4 font_size_sm_h3 mt-4">
              Conte com o Crédito Consignado para equilibrar sua vida
              financeira, realizar seus sonhos e projetos para sua família!
            </h2>
            <p className="mb-5">
              As menores taxas de juros em empréstimo pessoal, mais tempo para
              pagar e parcelas debitadas diretamente do holerite ou benefício.
              <br />O dinheiro entra direto na sua conta bancária e não é
              necessário informar o motivo do empréstimo ou comprovar o uso.
              Oferecemos as melhores opções de créditos e consórcios de forma
              prática e segura.
            </p>


            <h3 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
              Nós temos as melhores condições para:
            </h3>

            <ul id="saque-aniversario-secao-01" className="list-unstyled">
              <li className="d-flex align-items-start mb-3 mt-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Aposentados e Pensionistas</span>
              </li>

              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Empresas Privadas</span>
              </li>
              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Forças Armadas Brasileira</span>
              </li>
              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Funcionários Públicos</span>
              </li>
              <li className="d-flex align-items-start mb-4">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Polícia Militar</span>
              </li>
            </ul>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Contratar agora
        </button>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <button
                  type="button"
                  className="btn-close "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <h4
                className="modal-title mx-auto text-uppercase"
                id="exampleModalLabel"
              >
                Crédito Consignado
              </h4>
              <h6
                className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                id="exampleModalLabel"
              >
                Dados Pessoais
              </h6>
              <div className="modal-body">
                <form
                  id="form_credito_consignado"
                  name="form_credito_consignado"
                  onSubmit={handleSubmitConsignedCredit}
                >
                  <div className="input-group mb-3">
                    <label
                      className="input-group-Text"
                      htmlFor="nome_consignado"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Digite seu nome"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="nome_consignado"
                      name="nome_consignado"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <label
                      className="input-group-Text"
                      htmlFor="email_consignado"
                    >
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Digite seu e-mail"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="email_consignado"
                      name="email_consignado"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <label
                      className="input-group-Text"
                      htmlFor="phone_consignado"
                    >
                      Celular
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Digite seu número"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="phone_consignado"
                      name="phone_consignado"
                      onChange={({ target }) => setPhone(unMask(target.value))}
                      value={masker(phone, phone_pattern)}
                    />
                  </div>
                  <h6 className="modal-title  mt-lg-2" id="exampleModalLabel">
                    Regime de trabalho
                  </h6>

                  <div className="d-lg-flex justify-content-between px-lg-3">
                    <div className="">
                      <p className=" mb-lg-0 mt-lg-1">
                        Em qual tipo de instituição você trabalha?
                      </p>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setTypeInss(e.target.value)}
                        value={typeInss}
                      >
                        <option selected> [Selecione]</option>
                        <option value="Pública">Pública</option>
                        <option value="Privada">Privada</option>
                      </select>
                    </div>

                    <div className="">
                      <p className=" mb-lg-0 mt-lg-1">
                        Você é aposentado ou pensionista do INSS?
                      </p>
                      <select
                        className="form-select form-select-sm mb-4"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setRetiredPensioner(e.target.value)}
                        value={retiredPensioner}
                      >
                        <option selected> [Selecione]</option>
                        <option value="SIM">Sim</option>
                        <option value="NÃO">Não</option>
                      </select>
                    </div>
                  </div>

                  <div className="d-lg-flex justify-content-between px-lg-3">
                    <div className="mb-3">
                      <p className=" mb-lg-0 mt-lg-1">Selecione o convênio:</p>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setInsurance(e.target.value)}
                        value={insurance}
                      >
                        <option selected> [Selecione]</option>

                        {conveniosConsorcios.map(({ id, name }) => {
                          return (
                            <option value={name} key={id}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Enviar
                    </button>
                  </div>

                  <ToastContainer />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

export default Credit;
