import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import ReactPlayer from "react-player/file";

const About = () => {
  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Conheça a Mais Valor:
        // Uma consultoria financeira
        // personalizada para você!"
        background_image="sobrenos.png"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className=" pt-5 pt-lg-2 pb-5  pb-lg-0">
          <div className="col-md-12 text-center ">
            <h1 className="mb-5 font_size_sm  text-center mt-5">Sobre Nós</h1>
            <div className="">
              <div className="col-md-12 text-justify ">
                <p>
                  A Mais Valor é uma das maiores gestoras de negócios,
                  especializada em assessoria financeira para pessoas físicas e
                  empresas, com agências e representantes distribuídos pelos
                  principais estados do Brasil.
                </p>
                <p>
                  Ao longo de mais de 13 anos, nosso propósito é ajudar pessoas
                  e famílias a realizarem seus sonhos de maneira prática, segura
                  e eficiente. Por isso, oferecemos as melhores condições de
                  crédito consignado, crédito pessoal, antecipação de saque
                  FGTS, crédito com garantia de imóvel, crédito com garantia de
                  veículo, consórcios, portabilidade de empréstimo e cartão
                  benefício.
                </p>
                <p className="mb-5">
                  Nossos serviços estão disponíveis para aposentados,
                  pensionistas, servidores públicos e funcionários de empresas
                  privadas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="counters d-flex justify-content-center    flex-wrap flex-md-nowrap color_sobre">
          <div
            className="col-md-4 text-center aos-init aos-animate mt-5"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            <h2 className="number ">Missão</h2>
            <div className="content-image-number mt-lg-4 mb-4">
              <img
                src={images["icons8-objetivo-80.png"]}
                // width="100="
                // height="89"
                alt="Empréstimo Sim"
              />
            </div>
            <div>
              <p className=" text_custom">
                Sermos os melhores no que fazemos, inovando e melhorando
                continuamente.
              </p>
            </div>
          </div>

          <div
            className="col-md-4 text-center aos-init aos-animate mt-5"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            <div className="item-number">
              <h2 className="number ">Visão</h2>
            </div>
            <div className="content-image-number mt-lg-4 mb-4">
              <img
                src={images["icons8-binóculos-80.png"]}
                // width="100"
                // height="89"
                alt="Empréstimo Sim"
              />
            </div>
            <div>
              <p className="text_custom">
                Queremos ser referência na venda de produtos e serviços
                financeiros. Com paixão, alta performance e orgulho.
              </p>
            </div>
          </div>

          <div
            className="col-md-4 text-center aos-init aos-animate mt-5"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            <h2 className="number ">Valores</h2>
            <div className="content-image-number mt-lg-4 mb-4">
              <img
                src={images["icons8-balança-80.png"]}
                // width="100"
                // height="89"
                alt="Empréstimo Sim"
              />
            </div>
            <div>
              <p className=" text_custom ">
                Transparência, Solidez, Integridade e Confiança, Respeito nas
                relações, Qualidade nas entregas, Simplicidade, Comprometimento,
                Proatividade, Capacidade de Realização, Coragem, Ousadia,
                Comunicação clara e precisa, Sentimento de Pertencimento, Ética e
                Credibilidade, Dor de dono.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-main-numbers bg-numbers mt-lg-5 mb-lg-5">
        <div className="container">
          <div
            className="col-md-12 text-center aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="0.3s"
          >
            <h2 className="main-title-desktop mt-5 color_sobre main-title-mobile mb-lg-5 text-black pb-30">
              Nossos números
            </h2>
          </div>
          <div className="counters d-flex justify-content-center align-items-center flex-wrap flex-md-nowrap color_sobre">
            <div
              className="col-md-4 text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number mt-lg-4 mb-4">
                <img
                  src={images["icons8-brasilll-100.png"]}
                  width="100="
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number">
                <h2 className="number fs-4">
                  12 <span>escritórios</span>
                  <br></br> + Matriz (São Paulo)
                </h2>
              </div>
            </div>

            <div
              className="col-md-4 text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number mt-lg-4 mb-4">
                <img
                  src={images["icons8-grupos-de-usuários-100.png"]}
                  width="100"
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number  ">
                <h2 className="number fs-4">
                  + 1.2 Milhões{" "}
                  <span>
                    {" "}
                    <br></br>de clientes
                  </span>
                </h2>
              </div>
            </div>

            <div
              className="col-md-4 text-center aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              <div className="content-image-number d mt-lg-4 mb-4">
                <img
                  src={images["icons8-carteira-100.png"]}
                  width="100"
                  height="89"
                  alt="Empréstimo Sim"
                />
              </div>
              <div className="item-number">
                <h2 className="number fs-4">
                  10 <span>soluções</span>{" "}
                  <span>
                    <br></br>de crédito
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <h2 className="main-title-desktop mt-lg-5 play_margin_top color_sobre text-center main-title-mobile mb-lg-5 text-black pb-30">
            Descubra mais sobre nossa empresa:
          </h2>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/R477dL1ju94?si=nkBloqIPaBPLNhIB" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
      </div>

      <div className="d-table mx-auto  ">
        <Link
          className="btn btn-success mt-3 mb-4 hover_products border_radius_btn text-decoration-none"
          to="/produtos"
        >
          Conheça nossos produtos
        </Link>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);
const video = importAll(
  require.context("../assets/videos/", false, /\.(mp4)$/)
);
export default About;
