import React from "react";
import "./SimulatorEstate.css";

function SimulatorEstate() {
  return (
    <div className="image_simulator">
      <div className="container">
        <div className="row">
          <div className="">
            <div className="card margin_top_lg d-lg-flex gap-3 w-75  mx-auto py-5">
              <div className="card-body  ">
                <h5 className="card-title">Simulador CGI</h5>
                <div className="d-lg-flex gap-5 justify-content-center mt-3 mb-3">
                  <input type="number" name="" id="" placeholder="insira suas parcelas" />
                  <input type="text" placeholder="insira seu valor" />
                  <input type="text" placeholder="insira seu valor" />
                </div>
                <p c>
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// function importAll(r) {
//   let images = {};
//   r.keys().forEach((item, index) => {
//     images[item.replace("./", "")] = r(item);
//   });
//   return images;
// }

// const images = importAll(
//   require.context("../../assets/img/", false, /\.(png|jpe?g|svg)$/)
// );

export default SimulatorEstate;
