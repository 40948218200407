import React, { useState } from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import { mask as masker, unMask } from "node-masker";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";

const Benefit = () => {
  const [message, setMessage] = useState("");
  const [money, setMoney] = useState("R$ 500,00");
  const [linkActive, setLinkActive] = useState("");
  const [inputMoney, setInputMoney] = useState("500");
  const formType = "CRÉDITO PESSOAL";

  const [whatsappMessage, setWhatsappMessage] = useState("");
  const [phone, setPhone] = useState("");
  // const [cpf, setCpf] = useState("");
  // const cpf_pattern = "999.999.999-99";
  const phone_pattern = "(99) 99999-9999";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmitBenefit = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.BENEFIT,
        name,
        email,
        phone,
        formType,
      });
      handleResetForm();
    } catch (err) {
      console.log(err);
    }
  };
  const handleSimulation = () => {
    var valueSimulator = money.replace(/\D/g, "");
    var message = `Quero contratar um crédito pessoal no valor ${formatMoney(
      valueSimulator
    )} `;
    setWhatsappMessage(message);
  };
  const handleUpdateRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = formatMoney(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoney(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      innerMessage(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setInputMoney("500");
    } else {
      setInputMoney(newValue);
    }
  };
  const formatMoney = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };
  const innerMessage = (value) => {
    if (parseInt(value) < 500) {
      setLinkActive("disabled");
      return setMessage(`Valor mínimo: R$ 500,00`);
    } else {
      setLinkActive("");
      setMessage("");
    }

    if (parseInt(value) > 30000) {
      setLinkActive("disabled");
      setMessage("Valor máximo: R$ 30.000,00");
      return;
    }
    setLinkActive("");
    setMessage("");
  };

  const handleResetForm = (e) => {
    setPhone("");
    setName("");
    setEmail("");
  };

  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Consórcio: uma maneira segura e econômica de conquistar seus sonhos."
        background_image="creditopessoal.jpg"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-4 font_size_sm mb-4">Crédito Pessoal</h1>

            <div className="col-sm-6 mb-4 margin_bottom_estate px-2">
              <div className="card ">
                <div className="card-body ">
                  <h5 className="card-title">Eu preciso de:</h5>
                  <div className="rangers">
                    <div className="input-range ">
                      <input
                        type="range"
                        name=""
                        id="max"
                        min="500"
                        max="30000"
                        maxLength="12"
                        onChange={(e) => {
                          setMoney(
                            new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(e.target.value)
                          );
                          setInputMoney(e.target.value);
                        }}
                        value={inputMoney}
                      />
                      <input
                        className="font_size_cosignado"
                        type="text"
                        inputMode="numeric"
                        maxLength="16"
                        onChange={handleUpdateRawValue}
                        value={money}
                      />
                    </div>
                  </div>
                  <span className="d-lg-flex text-danger my-1">{message}</span>

                  <br></br>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span>
                        <i>Min:</i>
                      </span>
                      <p>R$ 500,00</p>
                    </div>

                    <div>
                      <span>
                        <i>Max:</i>
                      </span>
                      <p>R$ 30.000,00</p>
                    </div>
                  </div>

                  {/* <Link
                      to="/simuladorimovel"
                      className={
                        "btn btn-success mt-3 hover_products border_radius_btn text-decoration-none"
                      }
                      target="blank"
                    >
                      Quero fazer uma simulação
                    </Link> */}
                  <a
                    href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                      5511913675781
                    )}&text=${encodeURIComponent(whatsappMessage)}`}
                    className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkActive}`}
                    onClick={handleSimulation}
                    target="blank"
                  >
                    Quero falar com um consultor
                  </a>
                  <p className="text-secondary">
                    <small>
                      *Horário de atendimento: seg a sex, das 9h às 18h.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <p className="mb-5">
              Crédito pessoal é uma modalidade de empréstimo que permite ás
              pessoas obterem um determinado valor em dinheiro para ser
              utilizado conforme suas necessidades,como pagar dívidas, realizar
              uma viagem, investir em um projeto pessoal, entre outros.
              <br />
              <strong>Flexibilidade.</strong> Ao contrário de outros tipos de
              empréstimos que têm finalidades especificas, como financiamento de
              veículos, o crédito pessoal não exige a comprovação do uso do
              dinheiro, dando a você liberdade para decidir onde empregar o
              valor.
            </p>


            <h5 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
              Vantagens do empréstimo
            </h5>
            <p className="mb-3">
              A Mais Valor possibilita seu acesso ao crédito!
            </p>

            <div className="d-flex gap_vehicle mb-4 flex-column align-items-center flex-lg-row flex-md-row">
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-dólar-americano-circulado-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Limite de<br></br> <strong>até R$100.000</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-percentagem-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Taxas a partir de<br></br> <strong>2,83% ao mês</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-calendário-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Pagamento em<br></br> <strong>até 96 vezes</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-notebook-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Contrate sem<br></br> <strong>sair de casa</strong>
                  </p>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Contrate agora
            </button>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title mx-auto text-uppercase "
                      id="exampleModalLabel"
                    >
                      Crédito Pessoal
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <h6
                    className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                    id="exampleModalLabel"
                  >
                    Dados Pessoais
                  </h6>
                  <div className="modal-body">
                    <form
                      id="form_personalcredit"
                      name="form_personalcredit"
                      onSubmit={handleSubmitBenefit}
                    >
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="nome_personalcredit"
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Digite seu nome"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="nome_personalcredit"
                          name="nome_personalcredit"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="email_personalcredit"
                        >
                          E-mail
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="email_personalcredit"
                          name="email_personalcredit"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="phone_personalcredit"
                        >
                          Celular
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Digite seu número"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="phone_personalcredit"
                          name="phone_personalcredit"
                          onChange={({ target }) =>
                            setPhone(unMask(target.value))
                          }
                          value={masker(phone, phone_pattern)}
                        />
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Enviar
                        </button>
                      </div>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);

export default Benefit;
