import React, { useState } from "react";
import { mask as masker, unMask } from "node-masker";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";

const RealEstate = () => {


  const formType = "GARANTIA DE IMÓVEL";
  const [phone, setPhone] = useState("");
  // const [cpf, setCpf] = useState("");
  const phone_pattern = "(99) 99999-9999";

  const [typeImmobile, setTypeImmobile] = useState();
  const [financed, setFinanced] = useState("");
  const [valueimmobile, setValueImmobile] = useState("R$ 0.00");
  const [message, setMessage] = useState("");
  const [money, setMoney] = useState("R$ 30.000,00");
  const [linkActive, setLinkActive] = useState("");
  const [inputMoney, setInputMoney] = useState("30000");

  const [whatsappMessage, setWhatsappMessage] = useState("");
  const [nameReatStateCredit, setNameReatStateCredit] = useState("");
  const [emailReatStateCredit, setEmailReatStateCredit] = useState("");

  const handleSubmitReatStateCredit = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.CREDITO_IMOBILIARIO,
        name: nameReatStateCredit,
        email: emailReatStateCredit,
        phone: phone,
        typeImmobile,
        financed,
        money,
        formType,
        valueimmobile
        // cpf: cpf,
      });
      /**
       * Função para resetar campos do formulário
       */
      handleResetForm();
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetForm = () => {
    // setCpf("");
    setPhone("");
    setNameReatStateCredit("");
    setEmailReatStateCredit("");
    setFinanced("");
    setTypeImmobile("");
    setMoney("R$ 30.000,00");
    setValueImmobile("")

  };

  const handleSimulation = () => {
    var valueSimulator = money.replace(/\D/g, "");
    var message = `Quero contratar CGI no valor ${formatMoney(
      valueSimulator
    )} `;
    setWhatsappMessage(message);
  };

  /**
   * @param event onChange
   * handleUpdateRawValue => atualiza e devolve o valor formatado
   */
  const handleUpdateRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = formatMoney(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoney(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      innerMessage(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setInputMoney("30000");
    } else {
      setInputMoney(newValue);
    }
  };

  const formatMoney = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };

  const innerMessage = (value) => {
    if (parseInt(value) < 30000) {
      setLinkActive("disabled");
      return setMessage(`Valor mínimo: R$ 30.000,00`);
    } else {
      setLinkActive("");
      setMessage("");
    }

    if (parseInt(value) > 20000000) {
      setLinkActive("disabled");
      setMessage("Valor máximo: R$ 20.000.000,00");
      return;
    }
    setLinkActive("");
    setMessage("");
  };

  const handleValueChange = (e) => {
    let inputValue = e.target.value;
    // Remover caracteres não numéricos
    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue === "") {
      inputValue = "0";
    }

    // Limitar o valor máximo a 20 milhões
    const maxValue = 2000000000;
    const numericValue = parseInt(inputValue, 10);
    const limitedValue = numericValue > maxValue ? maxValue : numericValue;

    // Formatar o valor
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(limitedValue / 100);

    setValueImmobile(formattedValue);
  };

  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Crédito Imobiliário"
        background_image="imobiliario1.png"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-5 font_size_sm mb-5">
              Crédito com Garantia de Imóvel
            </h1>
            <div className="row mb-3">
              <div className="col-sm-6 margin_bottom_estate ">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="card-title">
                      Empréstimo com garantia de imóvel
                    </h5>
                    <p className="card-text mb-4">
                      Use seu imóvel financiado ou não para ter as{" "}
                      <strong>menores taxas de juros.</strong>
                    </p>
                    <p className="card-text mb-5">
                      Só com a Mais Valor você tem até 240 meses para pagar e
                      uma consultoria financeira personalizada.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 margin_bottom_estate px-2">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Eu preciso de:</h5>
                    <div className="rangers">
                      <div className="input-range ">
                        <input
                          type="range"
                          name=""
                          id="max"
                          min="30000"
                          max="20000000"
                          maxLength="12"
                          onChange={(e) => {
                            setMoney(
                              new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(e.target.value)
                            );
                            setInputMoney(e.target.value);
                          }}
                          value={inputMoney}
                        />
                        <input
                          className="fs-4"
                          type="text"
                          inputMode="numeric"
                          maxLength="16"
                          onChange={handleUpdateRawValue}
                          value={money}
                        />
                      </div>
                    </div>
                    <span className="d-lg-flex text-danger my-1">
                      {message}
                    </span>

                    <br></br>
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>
                          <i>Min:</i>
                        </span>
                        <p>R$ 30.000,00</p>
                      </div>

                      <div>
                        <span>
                          <i>Max:</i>
                        </span>
                        <p>R$ 20.000.000,00</p>
                      </div>
                    </div>

                    <a
                      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                        5511913675781
                      )}&text=${encodeURIComponent(whatsappMessage)}`}
                      className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkActive}`}
                      onClick={handleSimulation}
                      target="blank"
                    >
                      Quero falar com um consultor
                    </a>
                    <p className="text-secondary">
                      <small>
                        *Horário de atendimento: seg a sex, das 9h às 18h.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="mb-4">
              O CGI - Crédito com garantia de imóvel é uma modalidade de
              empréstimo com taxas mais baixas e prazos mais longos, uma
              oportunidade para quem precisa de um alto valor.
            </p>
            <ul id="saque-aniversario-secao-01" className="list-unstyled">
              <li className="d-flex align-items-start mb-2 mt-2">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Até 240 meses para pagar</span>
              </li>

              <li className="d-flex align-items-start mb-2">
                <i className="text-success  mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Libera até 60% do valor do imóvel</span>
              </li>

              <li className="d-flex align-items-start mb-2">
                <i className="text-success  mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Até 6 meses de carência</span>
              </li>

              <li className="d-flex align-items-start mb-2">
                <i className="text-success  mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Disponível para negativado</span>
              </li>
            </ul>



            <h4 className="mt-4 mb-4">
              Vantagens do empréstimo com garantia de imóvel:
            </h4>

            <div className="d-flex gap_vehicle mb-4 flex-column align-items-center flex-lg-row flex-md-row  ">
              <div className="card bg-success mb-3 card_veiculo ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-ampulheta-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Pagamento com<br></br> <strong>prazo flexível</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_veiculo  ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-selecionado-40-1.png"]}
                  />{" "}
                  <p className="card-text">
                    Linha de <br></br> <strong>crédito segura</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_veiculo  ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-foguete-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Dinheiro rápido<br></br> <strong>na conta</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_veiculo ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-atendimento-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Atendimento <br></br> <strong>por especialistas</strong>
                  </p>
                </div>
              </div>
            </div>

            <h4 className="mb-4 font_size_sm_h3 mt-3">
              Como funciona o Crédito com Garantia de Imóvel?
            </h4>
            <ul id="saque-aniversario-secao-01" className="list-unstyled">
              <li className="d-flex align-items-start mb-2 mt-2">
                <img
                  className=" mr-1 "
                  alt="mensagem"
                  src={images["icons8-saco-de-dinheiro-20.png"]}
                />{" "}
                <span>Possuir uma casa, apartamento ou terreno;</span>
              </li>

              <li className="d-flex align-items-start mb-2">
                <img
                  className=" mr-1 "
                  alt="icone de dinheiro"
                  src={images["icons8-saco-de-dinheiro-20.png"]}
                />{" "}
                <span>O imóvel não precisa estar quitado;</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <img
                  className=" mr-1 "
                  alt="mensagem"
                  src={images["icons8-saco-de-dinheiro-20.png"]}
                />{" "}
                <span>Disponível para pessoa física e jurídica;</span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <img
                  className=" mr-1 "
                  alt="mensagem"
                  src={images["icons8-saco-de-dinheiro-20.png"]}
                />{" "}
                <span>Renda mínima de 2 mil reais.</span>
              </li>
            </ul>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Solicite sua simulação
        </button>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg ">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title mx-auto text-uppercase"
                  id="exampleModalLabel"
                >
                  Crédito com Garantia de Imóvel
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <h6
                className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                id="exampleModalLabel"
              >
                Dados Pessoais
              </h6>

              <div className="modal-body">
                <form
                  id="form_imobiliario"
                  name="form_imobiliario"
                  onSubmit={handleSubmitReatStateCredit}
                >
                  <div className="input-group mb-3 ">
                    <label
                      className="input-group-Text"
                      htmlFor="nome_imobiliario"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Digite seu nome"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="nome_imobiliario"
                      name="nome_imobiliario"
                      value={nameReatStateCredit}
                      onChange={(event) =>
                        setNameReatStateCredit(event.target.value)
                      }
                    />
                  </div>
                  <div className="d-lg-flex gap-lg-3">
                    <div className="input-group mb-3 ">
                      <label
                        className="input-group-Text"
                        htmlFor="email_imobiliario"
                      >
                        E-mail
                      </label>
                      <input
                        type="email"
                        className="form-control "
                        placeholder="Digite seu e-mail"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        id="email_imobiliario"
                        name="email_imobiliario"
                        value={emailReatStateCredit}
                        onChange={(event) =>
                          setEmailReatStateCredit(event.target.value)
                        }
                      />
                    </div>
                    <div className="input-group mb-3 ">
                      <label
                        className="input-group-Text"
                        htmlFor="phone_imobiliario"
                      >
                        Celular
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Digite seu número"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        id="phone_imobiliario"
                        name="phone_imobiliario"
                        onChange={({ target }) =>
                          setPhone(unMask(target.value))
                        }
                        value={masker(phone, phone_pattern)}
                      />
                    </div>
                  </div>
                  <h6
                    className="modal-title text-uppercase  mt-lg-2"
                    id="exampleModalLabel"
                  >
                    Simulação
                  </h6>

                  <div className="d-lg-flex justify-content-between px-lg-3">
                    <div className="">
                      <p className=" mb-lg-0 mt-lg-1">
                        Qual é o tipo de imóvel?
                      </p>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="apartamento"
                          name="tipo_imovel"
                          onChange={(e) => setTypeImmobile(e.target.value)}
                          value="apartamento"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="apartamento"
                        >
                          Apartamento
                        </label>
                      </div>
                      <div className="custom-control mb-2 custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="casa"
                          name="tipo_imovel"
                          onChange={(e) => setTypeImmobile(e.target.value)}
                          value="casa"
                        />
                        <label className="custom-control-label" htmlFor="casa">
                          Casa
                        </label>
                      </div>
                      <div className="custom-control mb-2 custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="terreno"
                          name="tipo_imovel"
                          onChange={(e) => setTypeImmobile(e.target.value)}
                          value="terreno"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="terreno"
                        >
                          Terreno
                        </label>
                      </div>
                    </div>

                    <div className="">
                      <p className=" mb-lg-0 mt-lg-1">
                        Imóvel está financiado atualmente?
                      </p>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="estate_sim"
                          name="estate_sim_name"
                          onChange={(e) => setFinanced(e.target.value)}
                          value="sim"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="estate_sim"
                        >
                          Sim
                        </label>
                      </div>
                      <div className="custom-control mb-2 custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="estate_nao"
                          name="estate_sim_name"
                          onChange={(e) => setFinanced(e.target.value)}
                          value="nao"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="estate_nao"
                        >
                          Nao
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <p className=" mb-lg-0 mt-lg-1">
                      Qual é o valor do seu imóvel?
                    </p>

                    <input
                      aria-label="default input example"
                      className="form-control"
                      placeholder="Mínimo R$150 mil"
                      id="valor_imovel"
                      type="text"
                      inputMode="numeric"
                      onChange={(e) => handleValueChange(e)}
                      value={valueimmobile}
                    />
                  </div>

                  <div className="col-12">
                    <p className=" mb-lg-0 mt-lg-1">
                      Quanto você quer de crédito?
                    </p>
                    <div className="rangers">
                      <div className="input-range mb-3 ">
                        <input
                          type="range"
                          name=""
                          id="max"
                          min="30000"
                          max="20000000"
                          maxLength="12"
                          onChange={(e) => {
                            setMoney(
                              new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(e.target.value)
                            );
                            setInputMoney(e.target.value);
                          }}
                          value={inputMoney}
                        />
                        <input
                          className="fs-4"
                          type="text"
                          inputMode="numeric"
                          maxLength="16"
                          onChange={handleUpdateRawValue}
                          value={money}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Enviar
                    </button>
                  </div>
                </form>
                {/* componente de mensagem toast */}
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);

export default RealEstate;
