import React, { useState } from "react";
import { mask as masker, unMask } from "node-masker";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";

const VehicleCredit = () => {
  const [phone, setPhone] = useState("");

  const formType = "CRÉDITO COMO GARANTIA DE VEÍCULO"

  const phone_pattern = "(99) 99999-9999";
  const [message, setMessage] = useState("");
  const [money, setMoney] = useState("R$ 5.000,00");
  const [whatsappMessage, setWhatsappMessage] = useState("");
  const [linkActive, setLinkActive] = useState("");
  const [inputMoney, setInputMoney] = useState("5000");

  const [hasVehicle, setHasVehicle] = useState("");

  const [nameReatStateCredit, setNameReatStateCredit] = useState("");
  const [emailReatStateCredit, setEmailReatStateCredit] = useState("");

  const [vehicleHalfPaid, setVehicleHalfPaid] = useState("");
  const [vehicleSettled, setVehiclesettled] = useState("");

  const handleSubmitReatStateCredit = async (e) => {
    e.preventDefault();

    try {
      fetchApi({
        productId: PRODUCTS.CREDITO_VEICULO,
        name: nameReatStateCredit,
        email: emailReatStateCredit,
        phone,
        hasVehicle,
        vehicleHalfPaid,
        vehicleSettled,
        formType
      });
      /**
       * Função para resetar campos do formulário
       */
      handleResetForm();
    } catch (err) {
      console.error(err);
    }
  };


  const handleResetForm = () => {
    // setCpf("");
    setPhone("");
    setNameReatStateCredit("");
    setEmailReatStateCredit("");
    setHasVehicle("");
    setVehicleHalfPaid("");
    setVehiclesettled("");
  };

  const handleSimulation = () => {
    var valueSimulator = money.replace(/\D/g, "");
    var message = `Quero contratar crédito com garantia de veículo no valor ${formatMoney(
      valueSimulator
    )} `;
    setWhatsappMessage(message);
  };
  const handleUpdateRawValue = (e) => {
    /**
     * Regex
     * remove caracteres não-numéricos
     */
    const rawValue = e.target.value.replace(/\D/g, "");

    /**
     * @var formattedValue guarda o valor formatado
     */
    const formattedValue = formatMoney(rawValue);
    /**
     * Atualiza o state com o novo valor
     */
    setMoney(formattedValue);
    let newValue = rawValue.substring(0, rawValue.length - 2);

    setTimeout(() => {
      innerMessage(newValue);
    }, 3000);

    if (parseInt(rawValue) === 0) {
      setInputMoney("5000");
    } else {
      setInputMoney(newValue);
    }
  };
  const formatMoney = (rawValue) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(rawValue / 100);
  };
  const innerMessage = (value) => {
    if (parseInt(value) < 5000) {
      setLinkActive("disabled");
      return setMessage(`Valor mínimo: R$ 5.000,00`);
    } else {
      setLinkActive("");
      setMessage("");
    }

    if (parseInt(value) > 150000) {
      setLinkActive("disabled");
      setMessage("Valor máximo: R$ 150.000,00");
      return;
    }
    setLinkActive("");
    setMessage("");
  };


  const handleChangeValues = (event) => {
    const { name, value } = event.target;

    if (name === "veiculo_quitado") {
      let veiculo_quitado = value === "Não";
      let stringValue = veiculo_quitado ? "Não" : "Sim"; // Convertendo booleano para string

      setVehicleHalfPaid(stringValue);
    } else if (name === "parcelas_pagas") {
      setVehiclesettled(value);
    }
  }

  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Crédito Imobiliário"
        background_image="creditoveiculo.jpeg"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-5 font_size_sm mb-5">
              Crédito com Garantia de Veículo
            </h1>
            <div className="row mb-4">
              <div className=" col-sm-6 margin_bottom_estate ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Eu preciso de:</h5>
                    <div className="rangers">
                      <div className="input-range ">
                        <input
                          type="range"
                          name=""
                          id="max"
                          min="5000"
                          max="150000"
                          maxLength="12"
                          onChange={(e) => {
                            setMoney(
                              new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(e.target.value)
                            );
                            setInputMoney(e.target.value);
                          }}
                          value={inputMoney}
                        />
                        <input
                          className="font_size_cosignado"
                          type="text"
                          inputMode="numeric"
                          maxLength="16"
                          onChange={handleUpdateRawValue}
                          value={money}
                        />
                      </div>
                    </div>
                    <span className="d-lg-flex text-danger my-1">{message}</span>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>
                          <i>Min:</i>
                        </span>
                        <p>R$ 5.000,00</p>
                      </div>

                      <div>
                        <span>
                          <i>Max:</i>
                        </span>
                        <p>R$ 150.000,00</p>
                      </div>
                    </div>
                    <a
                      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                        5511913675781
                      )}&text=${encodeURIComponent(whatsappMessage)}`}
                      className={`btn btn-success mt-lg-3 mb-lg-1 hover_products border_radius_btn text-decoration-none ${linkActive}`}
                      onClick={handleSimulation}
                      target="blank"
                    >
                      Quero falar com um consultor
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="mb-4 font_size_sm_h3 mt-4">
              Seu carro pode trazer as melhores condições de crédito para você!
            </h2>

            <p className="mb-4">
              Se você precisa de dinheiro, mas quer juros baixos e longo prazo
              de pagamento, o crédito com garantia de veículo te oferece isso e
              muito mais! É a sua chance de receber um dinheiro extra para
              quitar dívidas, empreender, realizar um sonho, etc.
            </p>

            <h6>Confira algumas regras:</h6>
            <ul id="saque-aniversario-secao-01" className="list-unstyled">
              <li className="d-flex align-items-start mb-2 ">
                <img
                  className=" mr-1 margin_top_vehicle "
                  alt="icone de dinheiro"
                  src={images["icons8-carro-20.png"]}
                />{" "}
                <span>
                  O veículo precisa ter no máximo 15 anos de fabricação;
                </span>
              </li>

              <li className="d-flex align-items-start mb-2">
                <img
                  className=" mr-1 margin_top_vehicle "
                  alt="icone de dinheiro"
                  src={images["icons8-saco-de-dinheiro-20.png"]}
                />{" "}
                <span>O veículo deve estar quitado e sem pendências;</span>
              </li>

              <li className="d-flex align-items-start mb-2">
                <img
                  className=" mr-1 margin_top_vehicle "
                  alt="icone de dinheiro"
                  src={images["icons8-inscrição-20.png"]}
                />{" "}
                <span>
                  O veículo deve estar no nome de quem vai contratar o
                  empréstimo.
                </span>
              </li>
            </ul>
          </div>
        </div>


        <h5 className="mt-3 mb-4">
          Vantagens do empréstimo com garantia de veículo
        </h5>
        <h6 className="mt-3 mb-4">
          Condições mais flexíveis e vantajosas para o seu bolso
        </h6>
        <div className="d-flex gap_vehicle mb-4 flex-column align-items-center flex-lg-row flex-md-row">
          <div className="card bg-success mb-3 card_veiculo ">
            <div className="card-body text-white text-center">
              <img
                className="mb-2"
                alt="icone de dinheiro"
                src={images["icons8-percentagem-40.png"]}
              />{" "}
              <p className="card-text">
                Taxas a partir de <br></br> <strong> 1,20% ao mês</strong>
              </p>
            </div>
          </div>
          <div className="card bg-success mb-3 card_veiculo ">
            <div className="card-body text-white text-center">
              <img
                className="mb-2"
                alt="icone de dinheiro"
                src={images["icons8-calendário-40.png"]}
              />{" "}
              <p className="card-text">
                Pagamento em<br></br> <strong>até 60x</strong>
              </p>
            </div>
          </div>
          <div className="card bg-success mb-3 card_veiculo ">
            <div className="card-body text-white text-center">
              <img
                className="mb-2"
                alt="icone de dinheiro"
                src={images["icons8-carro-40.png"]}
              />{" "}
              <p className="card-text">
                Receba até 80% <br></br> <strong>do valor do carro</strong>
              </p>
            </div>
          </div>
          <div className="card bg-success mb-3 card_veiculo ">
            <div className="card-body text-white text-center">
              <img
                className="mb-2"
                alt="icone de dinheiro"
                src={images["icons8-dinheiro-40.png"]}
              />{" "}
              <p className="card-text">
                Limite de <br></br> <strong>até R$150.000</strong>
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Contrate aqui
        </button>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title mx-auto text-uppercase"
                  id="exampleModalLabel"
                >
                  Crédito com Garantia de Veículo
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <h6
                className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                id="exampleModalLabel"
              >
                Dados Pessoais
              </h6>
              <div className="modal-body">
                <form
                  id="form_imobiliario"
                  name="form_imobiliario"
                  onSubmit={handleSubmitReatStateCredit}
                >
                  <div className="input-group mb-3">
                    <label className="input-group-Text" htmlFor="nome_veiculo">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Digite seu nome"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="nome_veiculo"
                      name="nome_veiculo"
                      value={nameReatStateCredit}
                      onChange={(event) =>
                        setNameReatStateCredit(event.target.value)
                      }
                    />
                  </div>
                  <div className="input-group mb-3">
                    <label className="input-group-Text" htmlFor="email_veiculo">
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Digite seu e-mail"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="email_veiculo"
                      name="email_veiculo"
                      value={emailReatStateCredit}
                      onChange={(event) =>
                        setEmailReatStateCredit(event.target.value)
                      }
                    />
                  </div>
                  <div className="input-group mb-3">
                    <label
                      className="input-group-Text"
                      htmlFor="phone_veiculo"
                    >
                      Celular
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Digite seu número"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="phone_veiculo"
                      name="phone_veiculo"
                      onChange={({ target }) => setPhone(unMask(target.value))}
                      value={masker(phone, phone_pattern)}
                    />
                  </div>

                  <h6
                    className="modal-title text-uppercase  mt-lg-2"
                    id="exampleModalLabel"
                  >
                    Simulação
                  </h6>

                  <div className="d-lg-flex justify-content-between px-lg-3">
                    <div className="mb-3">
                      <p className=" mb-lg-0 mt-lg-1">
                        Seu veículo tem menos de 15 anos?
                      </p>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="veiculo_menos"
                          name="veiculo_com_menos_quinze_anos"
                          value="Sim"
                          onChange={(e) => setHasVehicle(e.target.value)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="veiculo_menos"
                        >
                          Sim
                        </label>
                      </div>
                      <div className="custom-control mb-2 custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="veiculo_menos1"
                          name="veiculo_com_menos_quinze_anos"
                          value="Não"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="veiculo_menos1"
                        >
                          Não
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-lg-flex justify-content-between px-lg-3">
                    <div className="mb-3">
                      <p className=" mb-lg-0 mt-lg-1">
                        Seu carro está quitado?
                      </p>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="carro_quitado"
                          name="veiculo_quitado"
                          value="Sim"
                          onChange={handleChangeValues}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="carro_quitado"
                        >
                          Sim
                        </label>
                      </div>
                      <div className="custom-control mb-2 custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="carro_quitado1"
                          name="veiculo_quitado"
                          value="Não"
                          onChange={handleChangeValues}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="carro_quitado1"
                        >
                          Não
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="px-lg-3">
                    <div className={`mb-3 ${vehicleHalfPaid === 'Não' ? "" : "d-none"}`}>
                      <p className=" mb-lg-0 mt-lg-1">
                        Tem 50% das parcelas pagas?
                      </p>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="parcelas_pagas_sim"
                          name="parcelas_pagas"
                          value="Sim"
                          onChange={handleChangeValues}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="parcelas_pagas_sim"
                        >
                          Sim
                        </label>
                      </div>
                      <div className="custom-control mb-2 custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input sexo"
                          id="parcelas_pagas_nao"
                          name="parcelas_pagas"
                          value="Não"
                          onChange={handleChangeValues}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="parcelas_pagas_nao"
                        >
                          Não
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Enviar
                    </button>
                  </div>
                </form>
                {/* componente de mensagem toast */}
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);

export default VehicleCredit;
