import React, { useState } from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Amazing from "../components/Amazing";
import Footer from "../components/Footer";
import { mask as masker, unMask } from "node-masker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchApi } from "../utils/api";
import { PRODUCTS } from "../utils/products";

const Consortia = () => {
  const [phone, setPhone] = useState("");
  // const [cpf, setCpf] = useState("");
  // const cpf_pattern = "999.999.999-99";
  const phone_pattern = "(99) 99999-9999";
  const formType = "CONSÓRCIO";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [typeConsortia, setTypeConsortia] = useState("")

  const handleSubmitConsortium = async (e) => {
    e.preventDefault();
    try {
      fetchApi({
        productId: PRODUCTS.CONSORCIO,
        name: name,
        email: email,
        phone: phone,
        formType,
        consortia: typeConsortia
        // cpf: cpf,
      });
      handleResetForm();
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleResetForm = (e) => {
    // setCpf("");
    setPhone("");
    setName("");
    setEmail("");
    setTypeConsortia("")
  };


  return (
    <div className="App">
      <Menu menu={Menu}></Menu>
      <Header
        // intro="Consórcio: uma maneira segura e econômica de conquistar seus sonhos."
        background_image="consorcio.png"
        subintro=""
        header={Header}
      ></Header>
      <div className="container color_sobre">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="mt-5 font_size_sm mb-5">Consórcio</h1>
            <h2 className="mb-4 font_size_sm_h3 mt-4">
              Comprar o carro ou casa dos seus sonhos é muito fácil adquirindo
              um Consórcio com a Mais Valor.
            </h2>
            <p className="mb-5">
              O Consórcio é ideal par quem deseja adquirir bens, como casas,
              apartamentos, veículos, smartphones, notebooks, entre outros
              produtos, com planejamento e disciplina para investir a longo
              prazo.
              <br />
              No sistema de consórcio todos pagam uma parcela mensal com valor
              menor que financiamentos comuns.
              <br />
              A duração varia de acordo com o bem ou serviço escolhido e o tempo
              de contemplação acontece de acordo com os sorteios e lances.
              <br />
              Você está a um passo de conseguir a vida que sempre sonhou para
              você e sua família. Faça sua simulação agora e conte com um
              crédito seguro, taxas fixas e a experiência dos melhores
              consultores financeiros do mercado.
            </p>
            <h5 className="mt-3 mb-4">
              Tipos de consórcios oferecidos
            </h5>
            <div className="d-flex gap_vehicle mb-4 flex-column align-items-center flex-lg-row flex-md-row">
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-casa-401.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio <br></br> <strong>de imóveis</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-carro-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio<br></br> <strong>de carro</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-travel-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio<br></br> <strong>de viagens</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-semi-truck-side-view-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio <br></br> <strong>de caminhões</strong>
                  </p>
                </div>
              </div>

            </div>

            <div className="d-flex gap_vehicle mb-4 flex-column align-items-center flex-lg-row flex-md-row">
              <div className="card bg-success mb-3 card_imovel">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-trator-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio <br></br> <strong>de tratores</strong>
                  </p>
                </div>
              </div>

              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-celular-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio<br></br> <strong>de celular</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-motocicleta-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio <br></br> <strong>de moto</strong>
                  </p>
                </div>
              </div>
              <div className="card bg-success mb-3 card_imovel ">
                <div className="card-body text-white text-center">
                  <img
                    className="mb-2"
                    alt="icone de dinheiro"
                    src={images["icons8-notebook-40.png"]}
                  />{" "}
                  <p className="card-text">
                    Consórcio<br></br> <strong>de eletrônicos</strong>
                  </p>
                </div>
              </div>
            </div>


            <h5 className="font-weight-bold font_size_sm mb-0 mt-5 mb-lg-3 mb-xl-2">
              Conheça 7 vantagens do consórcio
            </h5>

            <ul id="saque-aniversario-secao-01" className="list-unstyled">
              <li className="d-flex align-items-start mb-3 mt-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Auxilia no planejamento financeiro</span>
              </li>

              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Adequado para qualquer condição financeira</span>
              </li>
              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Reduz a burocracia da compra</span>
              </li>
              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Não tem juros</span>
              </li>

              <li className="d-flex align-items-start mb-3">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>Prazo longo para pagamento</span>
              </li>
              <li className="d-flex align-items-start mb-4">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>
                  Possibilidade de dar lances
                </span>
              </li>
              <li className="d-flex align-items-start mb-4">
                <i className="text-success mr-2">
                  <FontAwesomeIcon icon={solid("circle")} size="2xs" />
                </i>
                <span>
                  Flexibilidade para usar o crédito
                </span>
              </li>
            </ul>

            <button
              type="button"
              className="btn btn-success  mb-4 hover_products border_radius_btn text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Contratar agora
            </button>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mx-auto text-uppercase" id="exampleModalLabel">
                      Consórcio
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <h6
                    className="modal-title text-uppercase ml-3  ml-lg-3 mt-lg-2"
                    id="exampleModalLabel"
                  >
                    Dados Pessoais
                  </h6>
                  <div className="modal-body">
                    <form
                      id="form_consorcio"
                      name="form_consorcio"
                      onSubmit={handleSubmitConsortium}
                    >
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="nome_consorcio"
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Digite seu nome"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="nome_consorcio"
                          name="nome_consorcio"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="email_consorcio"
                        >
                          E-mail
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="email_consorcio"
                          name="email_consorcio"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <label
                          className="input-group-Text"
                          htmlFor="phone_consorcio"
                        >
                          Celular
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Digite seu número"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="phone_consorcio"
                          name="phone_consorcio"
                          onChange={({ target }) =>
                            setPhone(unMask(target.value))
                          }
                          value={masker(phone, phone_pattern)}
                        />
                      </div>
                      <h6 className="modal-title  mt-lg-2" id="exampleModalLabel">
                        Regime de trabalho
                      </h6>


                      <div className="d-lg-flex justify-content-between px-lg-3">
                        <div className="">
                          <p className=" mb-lg-0 mt-lg-1">
                            Qual tipo de bem você quer adquirir?
                          </p>
                          <select
                            class="form-select mb-lg-3 form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setTypeConsortia(e.target.value)}
                            value={typeConsortia}
                          >
                            <option selected >
                              {" "}
                              [Selecione]
                            </option>
                            <option value="Consórcio de imóveis">Consórcio de imóveis</option>
                            <option value="Consórcio de carros">Consórcio de carros</option>
                            <option value="Consórcio de viagens<">Consórcio de viagens</option>
                            <option value="Consórcio de moto">Consórcio de moto</option>
                            <option value="Consórcio de caminhões e tratores">Consórcio de caminhões e tratores</option>
                            <option value="Comprar um notebook, um celular ou até uma bicicleta elétrica "></option>
                          </select>
                        </div>


                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Enviar
                        </button>
                      </div>

                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Amazing amazing={Amazing}></Amazing>
      <Footer footer={Footer}></Footer>
    </div>
  );
};
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);


export default Consortia;
